import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CommonComponentsModule } from './common/common.module';
import { SignupComponent } from './signup/signup.component';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { CalendarModule } from 'angular-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { ForgotAccountComponent } from './forgot-account/forgot-account.component';
import { CookieModule } from 'ngx-cookie';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './services/http.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { PaymentRedirectComponent } from './external/payment-redirect/payment-redirect.component';
import { ResetAccountConfirmComponent } from './reset-account-confirm/reset-account-confirm.component';
import { GenesysSetupComponent } from './genesys-setup/genesys-setup.component';

import { PendingChangesGuardGuard } from './services/pending-changes-guard.guard';
import { QuartzCronModule } from '@sbzen/ng-cron'
import {
	MatDialogModule
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { FolderPopUpComponent } from './folder-pop-up/folder-pop-up.component'
import { MatRadioModule } from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QueryBuilderModule } from 'angular2-query-builder';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCarouselModule } from 'ng-mat-carousel';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SignupComponent,
    VerifyAccountComponent,
    ForgotAccountComponent,
    PaymentRedirectComponent,
    ResetAccountConfirmComponent,
    GenesysSetupComponent,
    FolderPopUpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonComponentsModule,
    MatDialogModule,
    QueryBuilderModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatInputModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    HttpClientModule,
    QuartzCronModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatCarouselModule,
    OrganizationChartModule,
    AkitaNgRouterStoreModule.forRoot(),
    CookieModule.forRoot(),
    DragulaModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
    // CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    PendingChangesGuardGuard
  ],
  bootstrap: [AppComponent],
  schemas: [
     CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
