import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Amplify,{ Auth } from 'aws-amplify';
import { take } from 'rxjs/operators';
import { requestsService } from '../services/requests/requests.service';
import { UserQuery } from '../services/user/user.query';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  showError: string = null;
  showSuccess = false;
  submitted = false;
  orgName:any;
  invite:false;
  region:any
  //  error = { required: "Please accept the terms" };
  constructor(private router: Router,private apiService: requestsService,private routert: ActivatedRoute,private user: UserQuery,) {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z||0-9\d$@$!%*?&].{8,}')]),
      confirmPassword: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.pattern(/^[\?+]\d+$/)]),
      acceptTerms: new FormControl(false),
      selectedRegion:new FormControl('', [Validators.required]),
    })
    this.routert.params.pipe(take(1)).subscribe(params => {
      console.log(params)
     this.orgName=params.organizationId
     var pramasRegion=params.region
     this.region=pramasRegion;
    });
  }
  ngOnInit(): void {
    console.log(window.location.href)
  }

  setTerms(val) {
    this.signUpForm.controls.acceptTerms.patchValue(val);
  }

  setPhone(val) {
    this.signUpForm.controls.phone.patchValue(val);
  }
  getRegion(event){
    console.log(event)
    this.region=event
    this.user.setRegion(this.region);
    this.signUpForm.controls.selectedRegion.patchValue(this.region);
  }
  signup(event) {
    this.submitted = true;
    event.preventDefault();
    if (this.signUpForm.invalid) {
      this.signUpForm.controls.selectedRegion.markAsTouched();
      this.showError="Required fields are missing"
      return;
    }
    const params = this.signUpForm.value;
  console.log(params.firstName)
console.log(params.lastName)
    if (!params.acceptTerms) {
      this.showError = 'Please accept the terms of service';
      return;
    }

    if (params.password !== params.confirmPassword) {
      this.showError = 'Password is not matching';
      return;
    }
    console.log(params)
        console.log("signUp")
        Auth.signUp({
          username: params.email,
          password: params.password,
          attributes: {
            name: params.firstName,
            middle_name: params.lastName,
            email: params.email,
            phone_number: params.phone,
          }
        }).then(res => {
          this.showSuccess = true;
          var abv={
            user:params.email,
            password:params.password,
            org:this.orgName
          }
          setTimeout(() => this.router.navigate(['/verify'],{queryParams:abv, skipLocationChange: true }), 1000);
        }, err => {
          this.showError = err.message;
        });
  }

}
