import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { UserQuery } from '../services/user/user.query';
import {requestsService} from '../services/requests/requests.service'
//import {ApiClientClass} from 'purecloud-platform-client-v2';
import { Config } from '../config';
import {GenesystokenService} from '../services/genesystoken.service'
import {ReportsdetailsService} from '../services/reportsdetails.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showError: string = null;
  showSuccess = false;
  moduleList=[{
    name:"Dashboard", 
    route:""
  },
  {
    name:"Service", 
    route:"/service"
  },
  {
    name:"Script", 
    route:"/script"
  },
  {
    name:"Test Method", 
    route:"/test-method"
  },
  {
    name:"Reports", 
    route:"/reports"
  }];
  moduleListFiltred=[]
  platformclient:any;
  pcEnvironment:any;
  pcLanguage:any; 
  clientApp:any=null; 
  datatab:any;
  dataService:any;
  users:any;
  challengeName;
  showOTP=false;
  btnDisplay:boolean=false;
  loginUrl:any;
  region:any
  constructor(private router: Router,
    private user: UserQuery,private apiService: requestsService,private config:Config,private genesysServie:GenesystokenService,private propService:ReportsdetailsService,private toastr: ToastrService,private sanitizer: DomSanitizer,private routert: ActivatedRoute) {
      this.platformclient= (<any>window).platformClient;
      console.log(this.platformclient)
  
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(false),
      code:new FormControl(),
      nextPage: new FormControl(),
      route: new FormControl(),
      selectedRegion:new FormControl('', [Validators.required]),
    })
  }
  
  ngOnInit(): void {
    this.moduleListFiltred= this.moduleList;
    var data1=JSON.parse(localStorage.getItem('UserCred'))
    this.datatab=(localStorage.getItem('tabBrowser'));
    this.loginForm.controls.nextPage.setValue('Dashboard');
    this.loginForm.controls.route.setValue('');
    this.dataService=(localStorage.getItem('tabBrowserService'))
    console.log(this.dataService,this.datatab)
    console.log(window.location.href)
    if(this.datatab){
      this.router.navigateByUrl(this.datatab)
    }
    if(this.dataService){
      this.router.navigateByUrl(this.dataService)
    }
    console.log(JSON.parse(localStorage.getItem('UserCred')))
    console.log(this.genesysServie.tokenType);
    if(this.genesysServie.tokenType ==="genesys")
    {
      if(this.genesysServie._alreadyLogged == false){
        this.setDynamicParameters();
        // Setup Client App
        
        
        console.log(this.platformclient)
        const client= this.platformclient.ApiClient.instance;
        client.setEnvironment(this.pcEnvironment.replace(".login","").replace("/oauth",""));
        
        client.setPersistSettings(true,this.config.appName)
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams)
        // client.loginCodeAuthorizationGrant(this.config.clientID,this.config.clientSecret,"OAuth",this.config.premiumAppURL).then(res=>{
        //   console.log(res)
        // })
        client.loginImplicitGrant(this.config.clientID,this.config.wizardUriBase).then(res=>{
          this.genesysServie._alreadyLogged=true;
          console.log(res);
          this.genesysServie.dataToken = res.accessToken;
          console.log(this.genesysServie.dataToken)
          this.router.navigateByUrl('/home'+this.loginForm.controls.route.value);
          this.apiService.getUserdetails().subscribe(s=>{
            console.log(s);
            if(this.propService.EmailLink !=null){
              window.location.href=this.propService.EmailLink
              //this.router.navigateByUrl(this.propService.EmailLink)
            }
            else{
              this.router.navigateByUrl('/home'+this.loginForm.controls.route.value);
            }
           
             this.myBrowser()
          });
    
        // }).catch((e) => {
        //   alert('access_token')
        //   alert(e)
        //   console.error(e);
    
        }); 
      }
    
    }
    this.clearLocalStorage()
  }
  clearLocalStorage() {
    localStorage.removeItem('showFrequency');
    localStorage.removeItem('Frequencycheck');
}
  changeRoute(value){
    let route= this.moduleList.find(item=>item.name === value);
    this.loginForm.controls.route.setValue(route.route);
  }
  search(event){
    if(event.target.value){
      this.moduleListFiltred = this.moduleList.filter(item => item.name.toLowerCase().includes(event.target.value.toLowerCase()));
      if(this.moduleListFiltred.length===0){
        this.moduleListFiltred=this.moduleList
      }
    }else{
      this.moduleListFiltred=this.moduleList

    }
  }

  setRemember(val) {
    this.loginForm.controls.remember.patchValue(val);
  }
  codeVeryfy(){
    this.btnDisplay=true;
    console.log(this.loginForm.controls.code)
    const params = this.loginForm.value;
    Auth.confirmSignIn(this.users, this.loginForm.controls.code.value,'SMS_MFA').then(res =>{
      var lastLoginTime= res.signInUserSession?.idToken.payload.auth_time;
      console.log('Last Login Time:', lastLoginTime);
      const authTimeInMilliseconds = lastLoginTime * 1000;

      const date = new Date(authTimeInMilliseconds);
      const formattedDateTime = date.toLocaleString();
       this.user.setLoginTime(formattedDateTime);
       console.log(this.user.getLoginTime())
      console.log('Auth Time:', formattedDateTime);
      if (res.signInUserSession && res.signInUserSession.idToken) {
        this.showSuccess = true;
        this.user.setToken(res.signInUserSession.idToken.jwtToken);
        console.log(params.email);
        console.log(params.password);
        localStorage.setItem('UserCred', JSON.stringify({ Username: params.email, Password: params.password }));
          // Auth.currentAuthenticatedUser().then(user => {console.log("after",user)})
        this.apiService.getUserdetails().subscribe(s=>{
          console.log(s)
          this.btnDisplay=false
          this.router.navigateByUrl('/home'+this.loginForm.controls.route.value);
        });
        this.apiService.getRoleListByUsername().subscribe(res=>{
          console.log(res)
          this.user.setRole(res.role)
        })
        // this.user.setUserName(params.email);
        // this.user.setPassword(params.password);
        this.myBrowser();
      }
    }, err => {
     this.toastr.error("Please enter valid OTP code")
     this.btnDisplay=false
    })
  }
  onEnterKeyDown() {
    this.signIn()
  }
  onEnterKeyDownOtp() {
    this.codeVeryfy()
  }

  signIn() {
    // event.preventDefault();
    if (this.loginForm.invalid) {
      this.loginForm.controls.email.markAsTouched();
      this.loginForm.controls.password.markAsTouched();
      this.loginForm.controls.selectedRegion.markAsTouched();
      return;
    }
    const params = this.loginForm.value;
    this.showError = null;

    Auth.signIn(params.email, params.password).then((res: any) => {
      this.users=res
      if (res.signInUserSession && res.signInUserSession.idToken) {
        this.showSuccess = true;
        this.user.setToken(res.signInUserSession.idToken.jwtToken);
        var lastLoginTime= res.signInUserSession.idToken.payload.auth_time;
        console.log('Last Login Time:', lastLoginTime);
        const authTimeInMilliseconds = lastLoginTime * 1000;
  
        const date = new Date(authTimeInMilliseconds);
        const formattedDateTime = date.toLocaleString();
        this.user.setLoginTime(formattedDateTime)
        console.log('Auth Time:', formattedDateTime);
        console.log(params.email);
        console.log(params.password);
        localStorage.setItem('UserCred', JSON.stringify({ Username: params.email, Password: params.password }));
          // Auth.currentAuthenticatedUser().then(user => {console.log("after",user)})
        this.apiService.getUserdetails().subscribe(s=>{
          console.log(s)
          this.router.navigateByUrl('/home'+this.loginForm.controls.route.value);
        });
        this.apiService.getRoleListByUsername().subscribe(res=>{
          console.log(res)
          this.user.setRole(res.role)
        })
        // this.user.setMfA('NOSMS_MFA');
        // this.user.setUserName(params.email);
        // this.user.setPassword(params.password);
        this.myBrowser();
      }else if(res.challengeName=='SMS_MFA'){
        console.log(res)
        this.showOTP=true
        this.toastr.success("OTP send successfully")
      } else {
        console.log(res)
        this.showError = 'Something went wrong. Try again';
      }
    }, err => {
      this.showError = err.message;
    });
  }
  setDynamicParameters(){
   // Get Query Parameters
    const urlParams = new URLSearchParams(window.location.search);

    console.log(urlParams)
    let tempLanguage = urlParams.get(this.config.languageQueryParam);
    let tempPcEnv = urlParams.get(this.config.genesysCloudEnvironmentQueryParam); 
  
    // Language
    this.pcLanguage = tempLanguage || this.genesysServie.getLanguage ||
                // this.config.defaultLanguage;
  
    console.log(this.pcLanguage)
   
    this.genesysServie.setLanguage =this.pcLanguage
    // Environment
    
    this.pcEnvironment = tempPcEnv ||
                    this.genesysServie.getAppName
                     ||
                    // this.config.defaultPcEnvironment;

    console.log(this.pcEnvironment)
    this.genesysServie.setAppName= this.pcEnvironment;   
  }
  myBrowser() { 
    if( window.navigator.userAgent.indexOf("edge") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
        return 'edge';
    }else if(window.navigator.userAgent.indexOf("Firefox") != -1 ){
      this.toastr.success("For better Result please use Google Chrome")
      return 'Firefox'; 
    }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        return 'Chrome';
    }else if(window.navigator.userAgent.indexOf("Safari") != -1){
      this.toastr.success("For better Result please use Google Chrome")
        return 'Safari';
    }else if(window.navigator.userAgent.indexOf("AppleWebKit") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
         return 'AppleWebKit';
    }else if(window.navigator.userAgent.indexOf("edg") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
         return 'edge';
    }
    else {
      this.toastr.success("For better Result please use Google Chrome")
       return 'unknown';

    }

}
}